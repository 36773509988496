@import 'assets/scss/global';

.soloContainer {
  width: 448px;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}

.defaultContainer {
  width: 22em;
}