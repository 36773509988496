@import 'assets/scss/global';

.heading {
  @media screen and (max-width: $mobilePortraitWidth) {
    padding-top: 1rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.rowOne {
  @media screen and (max-width: $mobilePortraitWidth) {
    margin-top: .6rem;
  }
}

.rowTwo {
  font-size: 3.2rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    padding-top: 0rem;
    font-size: 2.5rem;
  }
}