@import 'assets/scss/global';
.main {
  display: flex;
  flex: 1;
  height: 100vh;
  max-height: 100dvh;
}

@media screen and (max-width: $mobilePortraitWidth) {
  .main {
    flex-direction: column-reverse;
  }
}
