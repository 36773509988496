.pricingCard {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: rgb(249, 249, 249);
  padding: 2.8rem;
  border-radius: 2rem;
  width: 100%;
  margin: 0 0 3.2rem 0;

  .price {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
  }

  .priceFirstColumn {
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
  }

  .priceSecondColumn {
    font-size: 1.5rem;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .priceSecondColumnFirstRow {
    font-weight: bold;
    color: #000000;
    font-size: .75rem;
  }

  .secondRow {
    padding: .25rem;
  }

  .thirdRow {
    padding: .25rem;
  }

  .normalPriceText {
    margin-top: .8rem;
  }

  .offerPill {
    background-color: #FADBFF;
    border-color: #FADBFF;
    font-weight: 600;
    padding: 4px 16px 4px 8px;
    margin-bottom: 2.4rem;
    width: fit-content;
  }
}
