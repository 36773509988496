@import 'assets/scss/global';
.productCard {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  max-height: 950px;
  margin-right: 0;
  width: unset;
  position: absolute;
  transition: margin-right 0.5s ease, transform 0.7s ease;
  transform: scale(0.65);

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
  }

  // Width
  @media screen and (min-width: $px-1024) {
    margin-right: -170px;
  }
  @media screen and (orientation: portrait) {
    margin-right: 0;
  }
  @media screen and (min-width: $px-1280) {
    transform: scale(0.67);
    margin-right: -240px;
  }
  @media screen and (min-width: $px-1536) {
    transform: scale(0.75);
    margin-right: -330px;
  }
  @media screen and (min-width: $px-1792) {
    transform: scale(0.8);
    margin-right: -440px;
  }
  @media screen and (min-width: $px-2048) {
    transform: scale(0.9);
    margin-right: -520px;
  }
  @media screen and (min-width: $px-2304) {
    transform: scale(1);
    margin-right: -700px;
  }

  // Height
  @media screen and (max-height: $px-624) {
    transform: scale(0.6);
    margin-right: 0;
  }

  @media screen and (max-width: $mobilePortraitWidth) {
    transform: scale(1);
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 0;
    position: relative;
    max-width: 100vw;
  }
}
