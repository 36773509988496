@import 'assets/scss/global';
.container {
  @media screen and (min-width: $mobilePortraitWidth) {
    margin-bottom: 20rem;
  }
}
.heading {
  margin-bottom: 3rem;
  font-size: 3.6rem;
}
.content p {
  font-size: 1.7rem;
  margin-bottom: .5rem;
  margin-top: 1rem;
}

.trialContent {
  display: flex;
  margin-bottom: 4rem;
  align-items: center;
  @media screen and (max-width: $mobilePortraitWidth) {
    flex-direction: column;
  }

  img {
    width: 12rem;
    margin-right: 3rem;
  }
}

.subscribeContent{
  margin-bottom: 4.5rem;
}

.nextSteps {
  margin-top: 2rem;
  margin-bottom: 2rem;
  :global(ol) {
    padding-left: .5rem;
  }
}
