@import 'assets/scss/global';

.error-page-top-bar {
  background: $dusk;
}

.error-page-container {
  display: flex;
  flex-direction: column;
}
.error-page-header {
  .error-page-header-myob-logo {
    display: inline-block;

    margin-left: 1rem;
    margin-top: 6rem;
    margin-bottom: 4rem;

    width: 10rem;
  }
}
.error-page-main {
  display: flex;
  justify-content: center;
  align-items: inherit;

  height: 85vh;

  .error-page-left-container {
    display: none;
  }

  .error-page-right-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    padding: 1rem;

    margin-bottom: 0;

    h1 {
      font-size: 4.5rem;
      margin-bottom: 1rem;
    }

    .error-page-right-container-tagline {
      font-size: 1.9rem;
    }

    button {
      width: 100%;

      a {
        color: white;
      }
    }
    .error-page-right-container-bottom-text-container {
      margin-top: 5rem;

      p:first-child {
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .error-page-top-bar {
    display: none;
  }

  .error-page-header {
    .error-page-header-myob-logo {
      width: 10rem;

      margin: 2rem;
      margin-left: 16rem;
    }
  }

  .error-page-main {
    align-items: center;

    .error-page-left-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-right: 8rem;
    }

    .error-page-right-container {
      width: initial;
      padding: 0;

      margin-bottom: 5rem;

      button {
        font-size: 1.4rem;
        width: 13.5rem;
      }
    }
  }
}
