@media screen and (min-width: 578px) {
  .feature-box-tick {
    margin-right: 0.3rem;
  }

  .feature-box-image-container {
    overflow: hidden;
    text-align: center;
    width: 100%;

    .feature-box-image {
      max-width: 450px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 578px) {
  .feature-box-image-container {
    display: none;
  }
  .feature-box-content-container{
    display: none;
  }
}
