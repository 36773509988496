@import 'assets/scss/global';
.productFooter {
  width: 100%;
  @media screen and (max-width: $mobilePortraitWidth) {
    max-height: 60vh;
    overflow: auto;
  }
}

.termsAndConditions {
  font-size: 1.1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}