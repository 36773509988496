@import 'assets/scss/global';

.new-feature-box {
  display: flex;
  background-color: $white;
}

@media screen and (min-width: 578px) {
  .new-feature-box {
    display: flex;
    width: 50%;
    background: $white;
  }
}

.trial-feature-box {
  display: flex;

  .right-summary{
    display: flex;
    @media screen and (min-width: 578px) {
      margin-left: 60px;
    }
  }
}

@media screen and (min-width: 578px) {
  .trial-feature-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 45%;
    background: $dusk;

    small {
      font-size: 1.4rem;
    }

    .feature-box-tick {
      color: green;
      margin-right: 0.3rem;
    }

    .feature-separator{
      opacity: 0.5;
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 578px) {
  .trial-feature-box {
    .feature-separator{
      opacity: 0.5;
      margin: 10px 0;
    }
  }
}

