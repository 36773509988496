@import 'assets/scss/global';
.infoPanel {
  display: flex;
  flex: 1;
  background: #F2E8FD;
  align-items: center;
  justify-content: right;
  flex-direction: row-reverse;
  &.default {
    background: #ffffff;
    justify-content: center;
    @media screen and (max-width: $mobilePortraitWidth) {
      background: #f9f9f9;
    }
  }
}

.spacer {
  flex: .2;
}

@media screen and (max-width: $mobilePortraitWidth) {
  .infoPanel {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex: 0;
    width: 100%;
  }
}