@import 'assets/scss/global';

.terms-of-use-checkbox {
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  @media screen and (min-width: 578px) {
    width: 32em;
    margin-bottom: 2.5rem;
  }
}

 .send-promotion-checkbox {
   font-size: 14px;
   margin-top: 1rem;
   margin-bottom: 2.5rem;
   @media screen and (min-width: 578px) {
     width: 32em;
     margin-bottom: 2.5rem;
   }
 }
