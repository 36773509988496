@import 'assets/scss/global';

.terms-body {
  font-size: 1.5rem;
  margin-top: 1.6rem;
  color: #FFFFFF;

  @media screen and (min-width: 578px) {
    font-size: 1rem;
    max-width: 90rem;
  }

  @media screen and (max-width: 578px) {
    display: none;
  }

  p {
    color: #FFFFFF;

    a {
      color: #FFFFFF;
      text-decoration: underline
    }
  }

  .link {
    color: #FFFFFF;
    text-decoration: underline
  }
}

.terms .link {
  color: #515E6D;
  text-decoration: underline
}
