@import 'assets/scss/global';
.productCard {
  width: 393px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  transition: transform 0.7s ease;

  // Width
  @media screen and (max-width: $px-1792) {
    transform: scale(1);
  }

  @media screen and (max-width: $px-1536) {
    transform: scale(0.9);
  }

  @media screen and (max-width: $px-1280) {
    transform: scale(0.85);
  }

  @media screen and (max-width: $px-1024) {
    transform: scale(0.75);
  }

  @media screen and (max-width: $mobilePortraitWidth) {
    transform: scale(1);
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 99;
  }

  .accordionContainer {
    // disabling accordion on width less than 578px
    @media screen and (min-width: $mobilePortraitWidth) {
      button {
        pointer-events: none;
      }
      .accordionContent {
        height: unset !important;
      }
    }
  }
}
