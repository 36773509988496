@import 'assets/scss/global';

.contentPanel {
  display: flex;
  width: 50%;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  background-color: $light-grey;

  .rightPanelBackgroundImage {
    display: none;
  }
}


@media screen and (max-width: $mobilePortraitWidth) {
  .contentPanel {
    flex-direction: column;
    align-items: flex-start;
    padding: $contentPanelTopPadding 2rem;
    width: 100vw;
    overflow-y: auto;
    justify-content: space-evenly;

    &::after {
      content: '';
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    &.solo {
      background-color: $lavendere;
      padding-top: 0;
      justify-content: flex-start;
    }
    &.default {
      background-color: $light-grey;
      padding: $contentPanelTopPadding 3rem;
      justify-content: space-evenly;
    }
  }

  .spacerForProductCard {
    padding-bottom: 14rem;
  }

  .contentPanelFaded {
    &::after {
      opacity: 0.8;
      background: rgb(0, 0, 0);
      top: 0;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.contentPanelContent {
    @media screen and (min-width: $mobilePortraitWidth) {
    padding: 2rem 12rem;
    max-height: 100%;
    overflow: auto;
    flex: 1;
    &.defaultContentPanelContent {
      padding: 2rem 8rem;
    }
  }
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}

@media screen and (max-width: $px-1536) and (min-width: $mobilePortraitWidth) {
  .scaleContent {
    @media screen and (min-width: $px-1280) {
      transform: scale(0.9);
    }
    @media screen and (max-width: $px-1280) {
      transform: scale(0.7);
    }
    @media screen and (max-width: $px-1024) {
      transform: scale(0.45);
    }
  }
}