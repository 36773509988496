@import 'assets/scss/global';

.featureListContainer {
  list-style-type: none;
  margin: 2rem 0;

  .successIcon {
    height: 24px;
    display: flex;
    align-items: center;
  }
}
