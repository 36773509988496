@import 'assets/scss/global';

.progressBar {
  padding-bottom: 3rem;
  width: 100%;
  @media screen and (min-width: $mobilePortraitWidth) {
    padding-bottom: min(6rem, 6vh);
    max-width: 544px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 578px) and (min-height: 624px) and (max-height: 900px) {
    position: unset;
    transform: scale(0.8);
  }

  &.complete {
    :global(.flx-step--complete) {
      :global(.flx-step__counter) {
        background-color: #008536;
      }
    }
  }
}