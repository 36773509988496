@import 'assets/scss/global';
.confirmationContainer {
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobilePortraitWidth) {
    background: $lavendere;
  }
}
$borderRadius: 99px;
.confirmationContent {
  display: flex;
  align-items: center;
  height: 90%;
  max-height: 900px;
  max-width: 1200px;
  background: #f9f9f9;
  border-radius: $borderRadius;
  width: 78vw;
  overflow: hidden;
  @media screen and (max-width: $mobilePortraitWidth) {
    height: 100vh;
    max-height: 100dvh;
    flex-direction: column;
    background: $lavendere;
    overflow: inherit;
  }
  [alt='solo'] {
    margin: 2rem 0 4rem;
    display: block;
  }
}

.desktopImage {
  display: none;
  padding: 1rem 0 1rem 1rem;
  overflow: hidden;
  img {
    border-top-left-radius: calc(#{$borderRadius} - 1rem);
    border-bottom-left-radius: calc(#{$borderRadius} - 1rem);
  }
  @media screen and (min-width: $mobilePortraitWidth) {
    display: flex;
    height: 100%;
  }
}

.mobileImage {
  display: none;
  justify-content: center;
  @media screen and (max-width: $mobilePortraitWidth) {
    display: flex;
    height: 20vh;
    object-fit: contain;
    margin: 0 -2rem 3rem;
    overflow: hidden;
  }
}
.downloadQRCodeImage {
  width: 240px;
  border-radius: 32px;
}

.desktopText {
  @media screen and (max-width: $mobilePortraitWidth) {
    display: none;
  }
}

.buttonContainer {
  gap: 2rem;
  flex-wrap: wrap;
  @media screen and (max-width: $mobilePortraitWidth) {
    flex-direction: column;
  }
  a {
    @media screen and (max-width: $mobilePortraitWidth) {
      width: 200px;
    }
  }
}

.imageButton {
  padding: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  @media screen and (min-width: $mobilePortraitWidth) {
    padding: 4rem 10.6rem;
  }
}