@import 'assets/scss/global';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}

.inputContainer {
  margin-bottom: 0;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
    margin-bottom: 0;
  }
}

.input {
  width: 38rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}

.backgroundImage {
  display: none;
  width: 100vw;
  height: auto;
  object-fit: contain;
  margin: 0 -2rem 2.4rem -2rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    display: flex;
    margin-bottom: 4rem;
  }
}
