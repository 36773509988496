@import 'assets/scss/global';
.soloButton {
  min-width: 30rem;
  border-radius: 100px;
  padding: 1.4rem 2.4rem;

  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}

.defaultButton {
  width: 100%;
}