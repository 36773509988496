.pricingMolecule {
  .leftInteger {
    font-size: 5rem;
    font-weight: 900;
    line-height: 1;
  }

  .centsAmount {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .denominator {
    font-size: 1.6rem;
    font-weight: 400;
    color: #45464A;
  }
}