@import 'assets/scss/global';

.expiry-date-input-right {
    width: 15em;
}

.cvc-input-right {
    margin-left: auto;
    width: 8em;
}

.horizontal-radio > div {
    margin-right: 2em;
}

.horizontal-radio div {
    display: inline-block !important;
    margin-bottom: 1em;
}