.input__field {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.75062em;
  padding: .3rem 1rem;
  border-radius: .25em;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  color: #515e6d;
  margin: 0;
}

.flx-loading-spinner--svg {
  display: inline;
}

.loading-font {
  line-height: 24px;
}

#bsb-loading-state div {
  display: inline;
}
