@import 'src/assets/scss/global';

.confirmation-top-bar {
  background: $white;
}

.confirmation-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .confirmation-page-title {
    font-size: 3.6rem;

    margin-top: 1rem;
    margin-bottom: 4rem;

    max-width: 25rem;
  }
  .confirmation-page-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;

    .confirmation-page-main-text-container {
      padding-top: 0.1rem;

      p {
        font-size: 1.7rem;
        margin-bottom: 0.5rem;
        max-width: 44rem;
        padding-top: 1rem;

        &:first-child {
          padding-top: 0;
        }

        a {
            text-decoration: none;
        }
      }
    }

    .confirmation-page-main-image {
      width: 14rem;
      margin-right: 0;
      margin-bottom: 4rem;
    }

    .confirmation-page-main-image-desc {
      padding-top: 0.1rem;

      p {
        text-align: center;
        font-size: 1.7rem;
        margin-bottom: 0.5rem;

        max-width: 30rem;
      }
    }
  }
}

@media screen and (min-width: 578px) {
  .confirmation-feature-box-right {
    padding-top: 30rem;
  }

  .confirmation-top-bar {
    display: none;
  }

  .confirmation-page-container {
    align-items: flex-start;

    .confirmation-page-title {
      max-width: inherit;
      text-align: left;

      margin-bottom: 3rem;
    }

    .confirmation-page-main {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 4.5rem;

      .confirmation-page-main-text-container {
        p {
          text-align: left;
          max-width: 54rem;
        }
      }

      .confirmation-page-main-image {
        width: 12rem;
        margin-right: 3rem;
        margin-bottom: 0;
      }

      .confirmation-page-main-image-desc {
        p {
          text-align: left;
          max-width: 42rem;
        }
      }
    }
  }
}
