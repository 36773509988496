@import 'assets/scss/global';

.container {
  width: 6.25em;
  margin-bottom: 1em;
}
.soloLogo {
  height: 4rem;
  margin: 0 0 4rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    margin: 0 0 2.4rem;
    &.hideLogo {
      display: none;
    }
  }
}