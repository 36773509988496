$golf: #00aa65;
$golf-light: #95D1B7;

.progress-button {
  background: linear-gradient(to right, $golf 50%, $golf-light 50%);
  background-origin: border-box;
  background-size: 200% 100%;
  background-position: left bottom;
  animation: background linear;
  animation-duration: 15s;

  a, span {
    color: white;
  }

  @keyframes background {
    from { background-position: right bottom; }
    to { background-position: left bottom; }
  }

  width: 100%;
}

@media screen and (min-width: 578px) {
  .progress-button {
    width: 250px;
  }
}