$candy: #ec0677;
$rouge: #b93499;
$dusk: #8241aa;
$regal: #6100a5;
$golf: #00aa65;
$zest: #00bc6f;
$light-grey: #f9f9f9;
$white: #ffffff;
$purple: #A130F5;
$lavendere: #F2E8FD;
$mobilePortraitWidth: 578px;
$px-624: 624px;
$px-1024: 1024px;
$px-1280: 1280px;
$px-1536: 1536px;
$px-1792: 1792px;
$px-2048: 2048px;
$px-2304: 2304px;

$contentPanelTopPadding: 4rem;

