@import 'assets/scss/global';

.duplicate-email {
  margin-bottom: 2rem;
}

.duplicate-buttons {
  display: flex;
  flex-direction: column;
}

.resume-trial-button {
  margin-bottom: 1rem;
}

@media screen and (min-width: 578px) {
  .duplicate-buttons {
    flex-direction: row;
  }

  .resume-trial-button {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}