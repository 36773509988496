@import 'assets/scss/global';
.backgroundImage {
  width: 100%;
  background-size: cover;
  height: 100vh;
  background-image: none;
  background-repeat: no-repeat;
  @media screen and (min-width: $px-1024) {
    background-image: url('../../assets/images/man-with-cap-1660.png');
    background-position: -300px 0;
  }
  @media screen and (max-width: $mobilePortraitWidth) {
    background-image: none;
  }
  @media screen and (orientation: portrait) {
    background-image: none;
  }
  @media screen and (min-width: $px-1280) {
    background-position: -340px 0;
  }
  @media screen and (min-width: $px-1536) {
    background-position: -290px 0;
  }
  @media screen and (min-width: $px-1792) {
    background-position: -230px 0;
  }
  @media screen and (min-width: $px-2048) {
    background-position: -260px 0;
  }
  @media screen and (min-width: $px-2304) {
    background-position: -240px 0;
  }

  // Height
  @media screen and (max-height: $px-624) {
    background-image: none;
  }

  @media screen and (max-width: $mobilePortraitWidth) {
    display: none;
  }
}