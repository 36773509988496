.hidden {
  display: none;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
}
