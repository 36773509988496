.terms {
  font-size: 1.5rem;
  margin-top: 1.6rem;
  max-width: 40em;
  color: #515E6D;

  @media screen and (min-width: 578px) {
    display: none;
  }
}
.main-content:has(.mobile-product-card), [data-testid='generic-main'] {
  .terms {
    display: none;
  }
}