.pricingCard {
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #F2E8FD;
  border-radius: 2rem;
  width: 100%;
  padding: 2.4rem;
  margin: 0 0 3.2rem 0;
  gap: 8px;
  .price {
    font-size: 3rem;
    display: flex;
  }

  .priceFirstColumn {
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
  }

  .priceSecondColumn {
    font-size: 1.5rem;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .priceSecondColumnFirstRow {
    font-weight: bold;
    color: #000000;
  }

  .secondRow {
    padding: .25rem;
  }

  .thirdRow {
    padding: .25rem;
  }

  .normalPriceText {
    font-size: 12px;
  }

  .offerPill {
    background-color: #FFFFFF;
    color: #6100A5;
    border-color: #6100A5;
    padding: 4px 16px 4px 8px;
    margin-bottom: 1.5rem;
    width: fit-content;
  }
}
