@import 'assets/scss/global';

.grecaptcha-badge {
  visibility: hidden;
}

.top-bar {
  background: $white;
  display: none;
}

.trial-top-bar{
  background: $dusk;
  display: none;
}

@media screen and (max-width: 578px) {
  .top-bar {
    display: block;
  }
  .trial-top-bar {
    display: block;
  }
}

.feature-box-right {
  position: relative;
  background-color: $light-grey;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15rem 3rem;
  .promo-code-group {
    &.isMobile {
      margin-left: -3rem;
      @media screen and (max-width: 578px) {
        width: 100vw;
        border-radius: 0;
      }
    }
  }
  @media screen and (min-width: 578px) {
    display: flex;
    padding: 20rem 8rem;
    .promo-code-group {
      &.isMobile {
        margin-left: 0;
      }
    }
  }
  @media screen and (min-width: 578px) and (min-height: 624px) {
    max-height: 100vh;
    overflow-y: auto;
  }
}

.main-content {
  background: $white;
  display: block;
  @media screen and (min-width: 578px) {
    min-height: 100vh;
    display: flex;
  }
}

.trial-main-content {
  background: $dusk;
  display: block;
  @media screen and (min-width: 578px) {
    min-height: 100vh;
    display: flex;
  }
}


.form-input {
  font-size: 1.6rem;
}
