@import 'assets/scss/global';
.progressBarContainer {
  width: 38rem;
  margin-bottom: 3.2rem;

  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

.progressBar {
  margin-bottom: 4rem;

  @media screen and (max-width: $mobilePortraitWidth) {
    margin-bottom: 2.4rem;
  }
}
