.pricingMolecule {
  .dollarSign {
    font-size: 5rem;
  }

  .dollarAmount {
    font-size: 5rem;
  }

  .centsAmount {
    font-size: 1.5rem;
  }

  .denominator {
    font-size: 2rem;
  }
}