@import 'assets/scss/global';

.recaptchaLink {
  color: #74767b;
  text-decoration-color: currentColor;
}

.recaptchaDescription {
  margin-top: 3rem;
  color: #74767b;
  font-size: 1rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    margin-top: 2rem;
  }
}
