@import 'assets/scss/global';

.chevron, .chevronOpen {
  width: 32px;
  height: 32px;
  transition: transform 0.5s ease;
  display: none;
  position: absolute;
  right: 2.4rem;
  top: 1.6rem;
  @media screen and (max-width: $mobilePortraitWidth) {
    display: block;
  }

  &.chevronOpen {
    transform: rotate(180deg);
  }
}

.rowOne::after {
  content: attr(data-content-desktop);
  display: block;

  @media screen and (max-width: $mobilePortraitWidth) {
    content: attr(data-content-mobile);
  }
}

.rowTwo::after {
  content: attr(data-content-desktop);
  display: block;

  @media screen and (max-width: $mobilePortraitWidth) {
    content: attr(data-content-mobile);
  }
}