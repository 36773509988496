@import 'assets/scss/global';

.backgroundImage {
  background-image: url('../../assets/images/solo-bg.svg');
  background-size: cover;
  background-position: 100%;
  height: 60%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  @media screen and (max-width: $mobilePortraitWidth) {
    display: none;
  }
}