@import 'assets/scss/global.scss';

.container {
  @media screen and (max-width: $mobilePortraitWidth) {
    :nth-child(2) {
      width: 100% !important;
    }
  }
}

.defaultPaymentModeContainer {
  display: flex;
  gap: 2em;
  margin: 0 0 2em;
}