@import 'assets/scss/global';

$px-360: 360px;
.promoCodeLink {
  padding: 0;
  margin-bottom: 1.6rem;
  text-decoration: underline;
}

:global(#promo-code-form) {
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
  }
}
.promoCodeGroup {
  width: 44rem;
  margin-top: 2rem;
  border-radius: 0.8rem;
  display: inline-block;

  .promoCodeInputText {
    min-height: 4rem;
    color: #070708;
    margin-top: 0.4rem;
    margin-bottom: 1.2rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .promoCodeInputContainer {
      margin: 0 1.2rem 0 0;
    }

    .promoCodeInputBox {
      display: flex;
      flex-direction: row;
      min-width: 22.9rem;
      height: 4rem;
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 400;
      &.valid {
        background: #E7FDF1;
        border: 0.2rem solid #008536;
        position: relative;
        padding-right: 2.4rem; /* Adjust padding to make space for the icon */
      }

      &.invalid {
        background: #FFE6E0;
        border: 0.2rem solid #FF221E;
      }

      @media screen and (max-width: $mobilePortraitWidth) {
        width: 100%;
        min-width: unset;
      }
    }

    .promoCodeInputTickIcon {
      color: #008536;
      width: 1.8rem;
      height: 1.8rem;
    }

    .promoCodeInputErrorIcon {
      /* Rectangle 292 */
      color: #CC0000;
      width: 1.8rem;
      height: 1.8rem;
    }

    .promoCodeApplyButton {
      width: 9rem;
      height: 4rem;
      background-color: $purple;
      color: $white;
      border-radius: 0.4rem;

      .promoCodeApplyButtonText {
        font-weight: 500;
        margin: 0;
        color: $white;
      }
    }
  }
  @media screen and (max-width: $mobilePortraitWidth) {
    width: 100%;
    .promoCodeInputText {
      .promoCodeInputContainer {
        flex: 1;
      }
      @media screen and (max-width: 400px) {
        .promoCodeApplyButton {
          min-width: 0;
        }
      }
    }

  }
}
